import { Box, Typography } from "@mui/material";
import React from "react";
import sampleProject from "../../../assets/sampleProject.png";

function ProjectCard() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "1px solid #E0E0E0",
        width: "350px",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginBottom: "1rem",
      }}
    >
      <img src={sampleProject} alt="some" />
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          padding: "0.7rem",
        }}
      >
        <Typography sx={{ color: "#101828", fontWeight: "bold" }}>
          Antimicrobial Guanidinylate Polycarbonates Show Oral in Vivo Efficacy
          Against Clostridioides Difficile
        </Typography>
        <Typography sx={{ color: "#475467", fontWeight: "thin" }}>
          Antimicrobial Guanidinylate Polycarbonates Show Oral in Vivo Efficacy
          Against Clostridioides Difficile
        </Typography>
      </Box>
    </Box>
  );
}

export default ProjectCard;
