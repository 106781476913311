import { Box, Divider, Typography } from "@mui/material";
import React from "react";

import AboutComponent from "./AboutComponent";
import Links from "./Links";

function About({ profile }) {
  console.log("Profile is for about:", profile);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <AboutComponent />
      <Links />
    </Box>
  );
}

export default About;
