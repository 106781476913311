import React from "react";
import { useState } from "react";
import { AppBar, Toolbar, Box, Button, IconButton } from "@mui/material";
import logo from "../assets/logo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import MobileDrawer from "./MobileDrawer";
import { useNavigate } from "react-router-dom";
import black_logo from "../assets/mask_group.svg";
import new_logo from "../assets/new_logo.png";
import menuButton from "../assets/menu_button.png";
import new_logo_svg from "../assets/icon_images/new_svg_logo.svg";

const pages = ["Home", "Features", "About", "Contact", "Pricing"];

const Navbar = ({ currentPage }) => {
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <AppBar
      sx={{
        background: "transparent",
        marginLeft: { xs: "0px", md: "auto" },
        marginRight: { xs: "0px", md: "auto" },
        width: "100vw",
        boxShadow: "none",
        height: { xs: "80px", md: "120px" },
        // boxShadow: {xs:"10px 2px 6px rgba(0, 0, 0, 1)", md:"none"},
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          width: "100%",
          marginLeft: { xs: "0px", md: "auto" },
          marginRight: { xs: "0px", md: "auto" },
        }}
      >
        <Box
          width="100%"
          padding={{ xs: "12px 5px 0px 2px", md: "0px 20px" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            marginLeft: { xs: "0px", md: "auto" },
            marginRight: { xs: "0px", md: "1rem" },
          }}
          // bgcolor="red"
        >
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width={{ xs: "100%", md: "auto" }}
            sx={{
              marginLeft: { xs: "0px", md: "auto" },
              marginRight: { xs: "0px", md: "auto" },
            }}
          >
            <Box
              sx={{
                padding: "0px",
                marginLeft: { xs: "0px", md: "auto" },
                marginRight: { xs: "0px", md: "auto" },
              }}
            > */}
          <img
            src={new_logo_svg}
            alt="logo"
            style={{
              height: "4.2rem",
              width: "auto",
              alignItems: "flex-start",
              marginLeft: "0px",
              // marginRight: "auto",
            }}
          />
          {/* </Box> */}
          <IconButton
            sx={{
              position: "absolute",
              right: "0",
              display: { xs: "block", md: "none" },
              marginBottom: "14px",
              // marginLeft: "10rem",
            }}
            onClick={() => setDrawerOpen(true)}
          >
            <img src={menuButton} alt="menu" />
          </IconButton>
          {/* </Box> */}

          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <MobileDrawer
              drawerOpen={drawerOpen}
              setDrawerOpen={setDrawerOpen}
              pages={pages}
              anchor="top"
            />
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              gap: "70px",
              marginTop: "30px",
            }}
            height="90%"
            marginBottom="33px"
            marginLeft="3.9rem"
            marginRight="53px"
          >
            {pages.map((page) => {
              return (
                <Button
                  onClick={() => {
                    navigate(`/${page.toLowerCase()}`);
                  }}
                  sx={{
                    background: "transparent",
                    borderRadius: "12px",
                    color: "white",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    letterSpacing: " 1px",

                    fontWeight: page === currentPage ? "800" : "500",
                    textTransform: "none",
                    "&:hover": {
                      background: "transparent",
                    },
                  }}
                >
                  {page}
                </Button>
              );
            })}
          </Box>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              onClick={() => {
                navigate("/login");
              }}
              sx={{
                background: "transparent",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Bold",
                fontSize: "13px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "28.08px",
                letterSpacing: " 1px",
                borderRadius: "5px",
                textAlign: "center",
                padding: "6px 20px",
                "&:hover": {
                  background: "transparent",
                  textDecoration: "underline",
                },
                display: { xs: "none", md: "flex" },
              }}
            >
              Login
            </Button>

            <Button
              sx={{
                background: "#3FA268",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Bold",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "28.08px",
                letterSpacing: "1px",
                borderRadius: "5px",
                textAlign: "center",
                padding: "4px 20px",
                "&:hover": {
                  background: "#3FA268",
                },
                display: { xs: "none", md: "flex" },
              }}
              onClick={() => {
                window.open(
                  "https://calendly.com/team-reslink/30-minute-meeting",
                  "_blank"
                );
              }}
            >
              Get Access
            </Button>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
