import React from "react";
import { Box, Card, Typography } from "@mui/material";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import bg from "../../assets/profileback.png";
import About from "./components/About";
import GetResearcherProfile from "../../helper/getResearcherProfile";
import { useLocation } from "react-router-dom";
import ErrorMain from "../Error_Page/ErrorMain";
import Experience from "./components/Experience";
import Projects from "./components/Projects";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResearcherProfile() {
  const [profile, setProfile] = React.useState(null);
  const [profileUUID, setProfileUUID] = React.useState(null);
  const [error, setError] = React.useState(false);
  const query = useQuery();
  const uuid = query.get("uuid");
  console.log("UUID is:", uuid);

  const fetchProfile = async () => {
    try {
      const response = await GetResearcherProfile({ uuid });
      console.log("Response for researcher profile is:", response);
      setProfile(response);
      console.log("Profile is:", profile);
      // setError(false);
    } catch (error) {
      console.log("Error for researcher fetch profile is:", error.message);

      if (error.message === "Request failed with status code 404") {
        // alert("No profile found.");
      } else {
        // alert(error.message);
      }
    }
  };
  React.useEffect(() => {
    if (!uuid) {
      // alert("Please provide a valid profile UUID to view the profile.");
      return;
    }
    setProfileUUID(uuid);
    fetchProfile();
  }, []);
  console.log("Profile is:", profile);

  return error ? (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        overflowY: "auto",
      }}
    >
      <ErrorMain />
    </Box>
  ) : (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <Navbar profileImage={profile?.profile_image || null} />
      <img
        src={profile?.profile_banner_image || bg}
        alt="bg"
        style={{
          width: "100%",
          height: "40%",
        }}
      />
      <Box
        sx={{
          width: "80%",
          marginX: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Header profile={profile} />
        <About profile={profile} />
        <Experience profile={profile} />
        <Projects profile={profile} />
      </Box>
    </Box>
  );
}

export default ResearcherProfile;
