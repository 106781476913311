import { Box, Typography } from "@mui/material";
import React from "react";
import ExperienceCard from "./ExperienceCard";
import ProjectCard from "./ProjectCard";

function Projects({ profile }) {
  const projects = [1, 2, 3];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "3rem",
        width: "100%",
      }}
    >
      <Typography sx={{ color: "#101828", fontWeight: "bold" }}>
        Research Projects
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {projects.map((project) => (
          <ProjectCard key={project} />
        ))}
      </Box>
    </Box>
  );
}

export default Projects;
