import React from "react";
import Navbar from "../../../components/Navbar";
import globe from "../../../assets/icon_images/globe.png";
import restart from "../../../assets/icon_images/restart.png";
import increase from "../../../assets/icon_images/increase.png";
import { Box, Card, Typography } from "@mui/material";
import { FeaturedServicesCards } from "../../Landing_Page/components/FeaturedServicesCards";
import new_hero_bg from "../../../assets/new_hero_bg.png";


const FeaturesHero = () => {
  return (
    <Box
      sx={{
        background:
          `url(${new_hero_bg})`,
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        minHeight: "100vh",
        height: "auto",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflowY: "auto",
        overflowX: "hidden",
        textAlign: "center",
      }}
    >
      <Navbar currentPage={"Features"} />
      <Box
        sx={{
          // textAlign: "left",
          width: "100%",
          marginTop: {xs:"30%",md:"10%"},
          // padding: { xs: "0px", md: "0px 30px" },
          alignSelf: "center",
          marginLeft: "auto",
          marginRight: "auto",
          // marginLeft: { xs: "0%", md: "1%" },
        }}
        zIndex={3}
      >
        <Box
          textAlign="center"
          width={{ xs: "93%", md: "100%" }}
          marginBottom="50px"
          marginLeft="auto"
          marginRight="auto"
        >
          <Typography
            sx={{
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Bold",
              fontSize: { xs: "1.8rem", md: "45px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal" /* 129.948% */,
              letterSpacing: " 1px",

              textAlign: "center",
            }}
          >
            <span style={{ color: "#3FA268" }}>AI-powered&nbsp;</span> SaaS for
            companies to <br />
            <span style={{ color: "#3FA268" }}>
              accelerate&nbsp;
            </span> their{" "}
            <span style={{ color: "#3FA268" }}>R&D process&nbsp;</span>{" "}
          </Typography>
          <Typography
            sx={{
              width: { xs: "92%", md: "100%" },
              // marginLeft: { xs: "13px", md: "0px" },
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Regular",
              fontSize: { xs: "16px", md: " 18px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "28.08px" /* 143.265% */,
              letterSpacing: " 1px",

              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Reslink is an AI-powered global R&D collaboration platform that
            connects researchers and <br />
            innovators from all over the world to accelerate innovation and
            create a brighter tomorrow.
          </Typography>
          <Box height="20px"></Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4,
              marginTop: "50px",
              flexDirection: { xs: "column", md: "row" },
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <FeaturedServicesCards
              icon={globe}
              title="R&D Simplified"
              subtext="Reslink provides everything you need to succeed in your R&D projects, from finding the best researchers and IPs to refining your approach and executing your plan."
            />
            <FeaturedServicesCards
              icon={restart}
              title="Resource Utilization"
              subtext="Reslink helps you to pull together the right resources for your project, including chemicals, labs, researchers, and equipments. We also help you to utilize your resources efficiently and effectively."
            />
            <FeaturedServicesCards
              icon={increase}
              title="Collaboration and Innovation"
              subtext="Reslink makes it easy for researchers and innovators to collaborate on projects and track their progress, which helps you to achieve your goals and get a better ROI."
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FeaturesHero;
