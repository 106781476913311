import { Box, Typography } from "@mui/material";
import React from "react";
import ExperienceCard from "./ExperienceCard";

function Experience({ profile }) {
  const Experiences = [1, 2, 3];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "3rem",
        width: "100%",
      }}
    >
      <Typography sx={{ color: "#101828", fontWeight: "bold" }}>
        Recent Experience
      </Typography>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {Experiences.map((experience) => (
          <ExperienceCard key={experience} />
        ))}
      </Box>
    </Box>
  );
}

export default Experience;
