import { Box, Typography } from "@mui/material";
import React from "react";
import arrow from "../../../assets/arrow-up-right.png";

function Links() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography sx={{ color: "#475467" }}>Portfolio</Typography>
          <Typography sx={{ color: "#087443", fontWeight: "bold" }}>
            www.ravi.wix.com
            <img src={arrow} alt="arrow" />
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: "#475467" }}>LinkedIn</Typography>
          <Typography sx={{ color: "#087443", fontWeight: "bold" }}>
            linkedin.com/ravig
            <img src={arrow} alt="arrow" />
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "3rem",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography sx={{ color: "#475467" }}>Research Gate</Typography>
          <Typography sx={{ color: "#087443", fontWeight: "bold" }}>
            @amelielaurent
            <img src={arrow} alt="arrow" />
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: "#475467" }}>Email</Typography>
          <Typography sx={{ color: "#087443", fontWeight: "bold" }}>
            ravindra@iitd.ac.in
            <img src={arrow} alt="arrow" />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Links;
