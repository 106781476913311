import { Box, Typography } from "@mui/material";
import React from "react";

function ExperienceCard() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: "1px solid #E0E0E0",
        padding: "1rem",
        width: "350px",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginBottom: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ color: "#101828", fontWeight: "bold" }}>
          Research Assistant
        </Typography>
        <Typography sx={{ color: "#475467" }}>IIT Delhi</Typography>
      </Box>
      <Typography sx={{ color: "#475467" }}>May 2020 - Present</Typography>
    </Box>
  );
}

export default ExperienceCard;
