import React from "react";
import { Box, Typography, Button } from "@mui/material";
import check_circle from "../../../assets/check-circle.svg";
import check_circle_white from "../../../assets/check-circle-white.svg";
import "./check_icon.css";

const PriceCard = ({ price, industry_type, points, popular }) => {
  return (
    <Box
      id="price-card-main"
      sx={{
        marginTop: { xs: "15px", md: "0px" },
        
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        marginLeft: "auto",
        marginRight: "auto",
        height: "580px",
        zIndex: 20,
        position: "relative",
        bgcolor:{xs:"rgba(255, 255, 255, 0.06)", md:"transparent"},

        "&:hover": {
          bgcolor: "#3FA268",
          transform: { md: "translate(0%, -10%)" },
          transition: "transform 0.5s ease",

          zIndex: 21, // Increase the z-index to make it appear above other elements
          "& button": {
            transition: "transform 2s ease",
            background: "white",
            color: "black",
          },
        },
        width: { xs: "95%", md: "27%" },
        padding: "10px 10px 23px 13px",
        borderRadius: "26px",
      }}
    >
      <div
        style={{
          marginLeft: "auto",
          marginRight: "0px",
          textAlign: "right",
          display: popular ? "block" : "none",
        }}
      >
        <div
          style={{
            width: "auto",
            height: "auto",
            flexShrink: "0",
            borderRadius: "13.5px",
            background: "rgba(248, 248, 248, 0.27)",
            textAlign: "center",
            padding: "3px 10px",
          }}
        >
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "normal",
              letterSpacing: "0.833px",
              // marginTop: "10px",
              textAlign: "center",
            }}
          >
            MOST POPULAR
          </Typography>
        </div>
      </div>
      <Box
        sx={{
          width: "83%",
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "left",
          marginTop: popular ? "-13%" : "21.5%",
        }}
      >
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Poppins",
            fontSize: "33px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "30px",
            letterSpacing: "-0.432px",
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          ${price}{" "}
          <span
            style={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Poppins",
              fontSize: "17px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "-0.432px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            / month
          </span>
        </Typography>
        <Box height="30px"></Box>
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Poppins",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            letterSpacing: "-0.432px",
            marginTop: "10px",
          }}
        >
          {industry_type}
        </Typography>

        <Box height="24px"></Box>

        {points.map((point) => (
          <Box
            sx={{
              display: "flex",
              gap: "1",
              alignItems: "center",
              textAlign: "left",
              marginLeft: "-3px",
              marginBottom: "13px",
            }}
          >
            <img id="my-img" src={check_circle} alt="check_circle" />
            <img
              id="my-img-white"
              src={check_circle_white}
              alt="check_circle_white"
            />
            <Typography
              sx={{
                width: "80%",
                textAlign: "left",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Medium",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26.08px",
                letterSpacing: "-0.432px",
                marginLeft: "10px",
                marginRight: "auto",
              }}
            >
              {point}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
          // width: "200px",
          background: "var(--Primary-Green, #25613d)",
          color: "var(--Primary-White, #FEFEFE)",
          fontFamily: "Poppins",
          fontSize: { xs: "15px", md: "16px" },
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "28.08px",
          letterSpacing: "-0.832px",
          borderRadius: "50px",
          textAlign: "center",
          textTransform: "none",
          // marginTop: "10px",
          padding: "8px 50px",
        }}
        onClick={() => {
          window.open("https://calendly.com/team-reslink/30-minute-meeting", "_blank");
        }}
      >
        Choose plan
      </Button>
    </Box>
  );
};

export default PriceCard;
