import { Box, Typography } from "@mui/material";
import React from "react";

function AboutComponent() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        maxWidth: "60%",
      }}
    >
      <Typography
        sx={{
          color: "#101828",
          fontWeight: "bold",
        }}
      >
        About me
      </Typography>
      <Typography
        sx={{
          color: "#475467",
        }}
      >
        I am Dr. Ravindra Gupta, an impassioned and innovative battery research
        scientist with over 12 years of dedicated experience in the field of
        electrochemical energy storage systems, particularly focusing on
        electric vehicle (EV) battery technologies. My academic journey
        culminated in a Ph.D. in Chemical Engineering from IIT Delhi, where I
        specialized in advanced material sciences and nanotechnology
        applications in energy storage.
      </Typography>
      <Typography
        sx={{
          color: "#087443",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        Read more
      </Typography>
    </Box>
  );
}

export default AboutComponent;
